/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "사이트 분석 도구"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.wappalyzer.com/"
  }, "Wappalyzer")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://builtwith.com"
  }, "Build With")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://radar.cloudflare.com/"
  }, "Cloudflare Radar")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "민주당"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://secure.actblue.com/"
  }, "Act Blue")), "\n", React.createElement(_components.li, null, "조 바이든 ", React.createElement(_components.a, {
    href: "http://joebiden.com"
  }, "joebiden.com"), " == https://secure.actblue.com/donate/web-bfp-july21-2024"), "\n", React.createElement(_components.li, null, "카멀라 해리스 ", React.createElement(_components.a, {
    href: "https://kamalaharris.com/"
  }, "https://kamalaharris.com/")), "\n", React.createElement(_components.li, null, "민주당 ", React.createElement(_components.a, {
    href: "https://democrats.org/"
  }, "https://democrats.org/")), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "공화당"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "도널드 트럼프 ", React.createElement(_components.a, {
    href: "https://www.donaldjtrump.com/"
  }, "https://www.donaldjtrump.com/")), "\n", React.createElement(_components.li, null, "공화당 ", React.createElement(_components.a, {
    href: "https://gop.com/"
  }, "https://gop.com/")), "\n"), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
